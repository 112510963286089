import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

Vue.use(Vuex);

// Interface State defines the state of kiosk
interface State {
	kiosk: boolean;
	lang: string;
	returnUrl: string;
}

// Initialize the state of kiosk to false
const state: State = {
	kiosk: false,
	lang: '',
	returnUrl: ''
};

// Enum MutationTypes defines the type of mutation
enum MutationTypes {
	TOGGLE_KIOSK_MODE = 'toggleKioskMode',
	SET_LANG = 'setLang',
	SET_RETURN_URL = 'setReturnUrl'
}

// Mutations object contains the logic for changing the state of kiosk
const mutations = {
	[MutationTypes.TOGGLE_KIOSK_MODE](state, value) {
		state.kiosk = value;
	},
	[MutationTypes.SET_LANG](state, value) {
		state.lang = value;
	},
	[MutationTypes.SET_RETURN_URL](state, value) {
		state.returnUrl = value;
	}
};

// Enum to store action types
enum ActionTypes {
	SET_KIOSK_MODE = 'setKioskMode',
	SET_LANG = 'setLang',
	SET_RETURN_URL = 'setReturnUrl'
}

// Object containing functions for each action type
const actions = {
	async [ActionTypes.SET_KIOSK_MODE]({ commit }, value) {
		// Function to set kiosk mode
		commit(MutationTypes.TOGGLE_KIOSK_MODE, value); // Commit mutation to toggle kiosk mode
	},
	async [ActionTypes.SET_LANG]({ commit }, value) {
		// Function to set language
		commit(MutationTypes.SET_LANG, value); // Commit mutation to set language
	},
	async [ActionTypes.SET_RETURN_URL]({ commit }, value) {
		// Function to set return URL
		commit(MutationTypes.SET_RETURN_URL, value); // Commit mutation to set return URL
	}
};

// Getter function to check if kiosk mode is enabled
const getters = {
	isKioskModeEnabled: (state: State) => state.kiosk, // returns boolean value indicating if kiosk mode is enabled or not
	getLang: (state: State) => state.lang, // returns the current language
	getReturnUrl: (state: State) => state.returnUrl // returns the return URL
};

const store: StoreOptions<State> = {
	state,
	mutations,
	actions,
	getters
};

export default new Vuex.Store(store);
