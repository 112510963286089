class BasicModeHandler {
	private _isEnabled: boolean = false;
	private _isApplied: boolean = false;

	get IsEnabled() {
		return this._isEnabled;
	}
	set IsEnabled(val: boolean) {
		this._isEnabled = val;
		this.Process();
	}

	private apply() {
		this._isApplied = true;

		document.head
			.querySelectorAll('#basic-mode')
			.forEach(i => document.head.removeChild(i));
		[...document.styleSheets].forEach(sheet => {
			var styles = sheet as CSSStyleSheet;
			if (
				styles.href ||
				!(styles.cssRules[0] as CSSStyleRule)?.selectorText?.startsWith(
					'.signaturePad '
				)
			) {
				sheet.disabled = true;
			}
		});

		var basicModeStyle = document.createElement('style');
		basicModeStyle.id = 'basic-mode';
		var css = `
            button {
                cursor: pointer;
            }

            .navbar-toggler::after{
                content: attr(data-expand-label);
            }
            .navbar-toggler[aria-expanded="true"]::after{
                content: attr(data-collapse-label);
            }        

            @media (min-width: 576px)
            .navbar-expand-sm .navbar-collapse {
                display: -webkit-box!important;
                display: -ms-flexbox!important;
                display: flex!important;
                -ms-flex-preferred-size: auto;
                flex-basis: auto;
            }

            *:not(canvas) {
                background-image: unset !important;
                background-color: initial !important;
                clip-path: none !important;
            }

            .tooltip,
            .accessibilityPanel.hide-accessibility,
            .svg-inline--fa,
            .switch_control,
            .scallop,
            .scallopBorder,
            footer div.logo,
            .d-none,
            .guide-me-container,
            .form-flow-container,
            .dropdown-menu,
            .pdf-viewer .sr-only,
            .pdf-text-layer,
            .logo,
            body.modal-open > div#app,
            div.menu-items div[role=button] button {
                display: none;
            }

            .skipmain {
                position: absolute;
                transform: translateY(-500%);
            }
            .skipmain:focus {
                position: relative;
            }

            .dropdown-menu.show,
            [role="dialog"] {
                display: block;
                background: white;
                border: 1px solid;
                padding: 1rem 2rem;
            }
            
            div.accessibility-overlay.show {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 100%;
                width: 100%;
                z-index: 9999;
                background-color: rgba(0,0,0,0.8) !important;
            }
            div.accessibilityPanel.show-accessibility {
                position: absolute;
                z-index: 10000;
                background-color: white !important;
                top: 20px;
                left: 20px;
            }
            div.accessibilityPanel .cloud-button {
                margin: 20px 0;
            }

            nav.header > ul.navbar-nav a::after {
                content: attr(aria-label);
            }

            div.primary, div.secondary {
                margin-top: 20px;
            }

            div.menu-items div[role=button] {
                border: 2px outset dimgray;
                margin: 10px 0;
                padding: 1px 6px;
                border-radius: 2px;
                cursor: pointer;
            }

            .queue-summary em {
                margin-left: 10px;
            }
            div.forms-list  > div.row {
                margin-top: 10px;
            }
            div.forms-list > div.row > div > button {
                display: none;
            }
            div.forms-list div[role=tabpanel] {
                display: block !important;
            }

            .form-field-container {
                margin-top: 20px;
            }
            
            .shift-label-item {
                display: table;
                margin: 1em 0;
            }
            .shift-label-item > * {
                display: table-header-group;
            }
            .toggle_button input,
            .toggle_button label.sr-only {
                display: none;
            }
            .toggle_button .toggle_option {
                padding: 2px 10px;
            }
            .toggle_button .toggle_radio:nth-of-type(1):checked ~ .toggle_control div.toggle_option:nth-of-type(1) span {
                text-decoration: underline;
                display: list-item;
                list-style: inside;
            }
            .toggle_button .toggle_radio:nth-of-type(2):checked ~ .toggle_control div.toggle_option:nth-of-type(2) span {
                text-decoration: underline;
                display: list-item;
                list-style: inside;
            }
            
            .required-marker-col[title="Required"]::after {
                content: "Required";
                margin-left: 5px;
                font-weight: 500;
            }
            
            .nav-button-container button span.d-none {
                display: initial;
            }
            
            .form-section-navigation button[title]::after {
                content: attr(title);
            }

            div.signature-acquisition {
                width: 80vw;
            }
            div.signature-classic canvas {
                width: 100%;
                height: 100%;
            }
            
            div.signature-classic {
                position: relative;
            }
            div.signature-classic p.waterMarkSig {
                position: absolute;
                top: 10px;
                left: 10px;
                margin: 0;
            }
            div.signature-classic .signaturePad {
                border: 1px solid;
            }

            form.signature-text > * {
                margin-right: 5px;
                margin-bottom: 10px;
            }
            
            .final-review button#done-button {
                margin-top: 10px;
            }
            
            div.modal[role="dialog"],
            div.modal-dialog
            {
                padding: 10px;
            }
            div.modal-content {
                padding: 10px;
            }
            
            div.card {
                margin-bottom: 10px;
            }
            
            ul.dropdown-menu.show {
                background: white !important;
                margin: 0;
            }

            @font-face{
                font-family: Dyslexic;
                src: url(../../fonts/OpenDyslexic3-Regular.0c774ed5.ttf) format("truetype"), url(../../fonts/OpenDyslexic-Regular.be1e6672.otf) format("opentype");
                font-weight:400;
                font-style:normal
            }
            @font-face{
                font-family: Dyslexic;
                src: url(../../fonts/OpenDyslexic3-Bold.57f36cc9.ttf) format("truetype"), url(../../fonts/OpenDyslexic-Bold.fbcbf443.otf) format("opentype");
                font-weight:700;
                font-style:normal
            }
            .font-dyslexic, .font-dyslexic * {
                font-family: Dyslexic,Segoe UI,Tahoma,Geneva,Verdana,sans-serif !important;
            }

            .enhance-selection button:focus, 
            .enhance-selection input:focus, 
            .enhance-selection textarea:focus,
            .enhance-selection select:focus,
            .enhance-selection a:focus {
                outline: 10px dotted #000!important;
            }

            button, select, input, textarea {
                font-size: inherit;
            }
            `;

		[75, 100, 150, 200].forEach(
			zoomLevel =>
				(css += `
            .zoom-${zoomLevel} { 
                font-size: ${zoomLevel}%;
            }
            .zoom-${zoomLevel} input[type=checkbox] {
                zoom: ${zoomLevel}%;
            }
        `)
		);
		basicModeStyle.innerHTML = css;
		document.head.appendChild(basicModeStyle);
	}
	private remove() {
		if (this._isApplied) {
			this._isApplied = false;
			document.head
				.querySelectorAll('#basic-mode')
				.forEach(i => document.head.removeChild(i));
			for (var i = 0; i < document.styleSheets.length; i++) {
				document.styleSheets[i].disabled = false;
			}
			window.dispatchEvent(new Event('resize'));
		}
	}

	public Process() {
		if (this._isEnabled) {
			this.apply();
		} else {
			this.remove();
		}
	}
}
export const basicMode = new BasicModeHandler();
