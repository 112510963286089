import axios, { AxiosResponse } from 'axios';
import { aw_api } from '@/aw_api';
import { environment } from '@/environment';
import { basicMode } from '@/basic-mode-handler';
import { EventBus } from '@/event-bus';
import VueGtag from 'vue-gtag';
import VueMask from 'v-mask';
import ImageUploader from 'vue-image-upload-resize';
import store from './store';

const browser = (function() {
	const userAgent = navigator.userAgent;
	let agentMatch =
		userAgent.match(
			/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
		) || [];
	if (/trident/i.test(agentMatch[1]) || /msie/i.test(agentMatch[1])) {
		const ie = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return ['IE', ie[1] || 0];
	}
	if (agentMatch[1] === 'Chrome') {
		const webkit = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
		if (webkit != null)
			return webkit
				.slice(1)
				.join(' ')
				.replace('OPR', 'Opera');
	}
	agentMatch = agentMatch[2]
		? [agentMatch[1], agentMatch[2]]
		: [navigator.appName, navigator.appVersion, '-?'];
	const versionMatch = userAgent.match(/version\/(\d+)/i);
	if (versionMatch != null) agentMatch.splice(1, 1, versionMatch[1]);
	return agentMatch;
})();

const canRunNormally = !(
	browser[0] === 'IE' ||
	(browser[0] === 'Chrome' && browser[1] < 51) ||
	(browser[0] === 'Firefox' && browser[1] < 51) ||
	(browser[0] === 'Safari' && browser[1] < 11)
);
if (canRunNormally) {
	Promise.all([
		axios.get(`env`),
		import(/* webpackChunkName: "vue" */ 'vue'),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/alert'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/button'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/card'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/layout'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/list-group'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/modal'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/spinner'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/components/toast'
		),
		import(
			/* webpackChunkName: "vue-bootstrap" */ 'bootstrap-vue/esm/directives/tooltip/tooltip'
		),
		import(/* webpackChunkName: "app-c" */ 'vue-signature'),
		import(/* webpackChunkName: "app-c" */ './App.vue'),
		import(/* webpackChunkName: "i18n" */ './i18n'),
		import(/* webpackChunkName: "router" */ './router'),
		new Promise(async resolve => {
			const { library } = await import(
				/* webpackChunkName: "fai-library" */ '@fortawesome/fontawesome-svg-core'
			);
			resolve(library);
		}),
		new Promise(async resolve => {
			const { FontAwesomeIcon } = await import(
				/* webpackChunkName: "vue-fa" */ '@fortawesome/vue-fontawesome'
			);
			resolve(FontAwesomeIcon);
		}),
		new Promise(async resolve => {
			const iconImports = [
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faArrowUp'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faBalanceScale'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faExclamationCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-primary-icons" */ '@fortawesome/free-solid-svg-icons/faQuestionCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faSignOutAlt'
				),
				import(
					/* webpackChunkName: "font-awesome-primary-icons" */ '@fortawesome/free-solid-svg-icons/faUniversalAccess'
				),
				import(
					/* webpackChunkName: "font-awesome-primary-icons" */ '@fortawesome/free-solid-svg-icons/faUnlockAlt'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faHome'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faMagic'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCalendarDay'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faUserMd'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faHospital'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faEnvelope'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCheck'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCheckCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCheckSquare'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faKeyboard'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faEdit'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faFile'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faPen'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faSearch'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faSearchPlus'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faSearchMinus'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faTimes'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faBan'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faChevronRight'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faChevronLeft'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faPhone'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faChevronDown'
				),
				import(
					/* webpackChunkName: "font-awesome-primary-icons" */ '@fortawesome/free-solid-svg-icons/faInfoCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faInfo'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faPlus'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faMinus'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faPencilAlt'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faWindowClose'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCalendar'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faTimesCircle'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faUser'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCamera'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faHeartbeat'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faDollarSign'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faAt'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faIdCard'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faFlag'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faWheelchair'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faUmbrella'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faUmbrellaBeach'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faFile'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faFileCircleCheck'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faSmile'
				),
				import(
					/* webpackChunkName: "font-awesome-icons" */ '@fortawesome/free-solid-svg-icons/faCircle'
				),
				import('@fortawesome/free-solid-svg-icons/fal')
			];

			Promise.all(iconImports).then(resolve);
		})
	]).then(
		([
			response,
			vue_mod,
			bv_alert_mod,
			bv_button_mod,
			bv_card_mod,
			bv_layout_mod,
			bv_listgroup_mod,
			bv_modal_mod,
			bv_spinner_mod,
			bv_toast_mod,
			bv_tooltip_mod,
			vueSignature_mod,
			app,
			i18n_mod,
			rH_mod,
			lib,
			vfa,
			iconModules
		]) => {
			const envResponse = response as AxiosResponse;
			const Vue = (vue_mod as any).default;
			const AlertPlugin = (bv_alert_mod as any).AlertPlugin;
			const ButtonPlugin = (bv_button_mod as any).ButtonPlugin;
			const CardPlugin = (bv_card_mod as any).CardPlugin;
			const LayoutPlugin = (bv_layout_mod as any).LayoutPlugin;
			const ListGroupPlugin = (bv_listgroup_mod as any).ListGroupPlugin;
			const ModalPlugin = (bv_modal_mod as any).ModalPlugin;
			const SpinnerPlugin = (bv_spinner_mod as any).SpinnerPlugin;
			const ToastPlugin = (bv_toast_mod as any).ToastPlugin;
			const VBTooltip = (bv_tooltip_mod as any).VBTooltip;
			const vueSignature = (vueSignature_mod as any).default;
			const App = (app as any).default;
			const i18n = (i18n_mod as any).default;
			const routingHandler = (rH_mod as any).default;
			const library = lib as any;
			const FontAwesomeIcon = vfa as any;

			if (envResponse && envResponse.status === 200 && envResponse.data) {
				if (envResponse.data != null) {
					environment.variable = envResponse.data;
					aw_api.axios = axios.create({
						...axios.defaults,
						baseURL: `${environment.variable.API_ROUTE}`.endsWith(
							'/'
						)
							? environment.variable.API_ROUTE
							: `${environment.variable.API_ROUTE}/`,
						headers: {
							...axios.defaults.headers,
							'AW-LI-From': 'AnyWHERE-Web',
							'AW-LI-Version':
								environment.variable.WEB_VERSION || 'Unknown'
						},
						withCredentials: environment.variable.USE_CORS == 'true'
					});
				}
			}
			(iconModules as any[]).forEach(i => library.add(i.definition));

			// Items very frequently used from Bootstrap-Vue can be added as plugins instead.
			const plugins: any[] = [
				vueSignature,
				AlertPlugin,
				ButtonPlugin,
				CardPlugin,
				LayoutPlugin,
				ListGroupPlugin,
				ModalPlugin,
				SpinnerPlugin,
				ToastPlugin
			];
			plugins.forEach(i => Vue.use(i));

			Vue.component('font-awesome-icon', FontAwesomeIcon);
			Vue.config.productionTip = false;
			Vue.directive('b-tooltip', VBTooltip);

			routingHandler.initialize(environment.variable.RELATIVE_BASE_PATH);
			const router = routingHandler.router;

			Vue.use(
				VueGtag,
				{
					config: {
						id: environment.variable.ANALYTICS_TRACKING_ID,
						enabled: false
					}
				},
				router
			);

			Vue.use(VueMask);
			Vue.use(ImageUploader);

			const basicModeMixin = {
				created() {
					EventBus.$on('basic-mode-set', (value: boolean) => {
						basicMode.IsEnabled = value;
					});
				},
				mounted() {
					basicMode.Process();
				},
				updated() {
					basicMode.Process();
				}
			};

			Vue.mixin(basicModeMixin);

			new Vue({
				i18n,
				store,
				router,
				render: h => h(App)
			}).$mount('#app');
		}
	);
} else {
	const appContainer = document.getElementById('app');
	if (appContainer) {
		appContainer.innerHTML =
			"<h1 style='text-align: center;'>The browser you are using is currently not supported.</h1>";
	}
}
