class Environment {
	private _variable: any = {};

	get variable(): any {
		return this._variable;
	}
	set variable(val: any) {
		this._variable = val;
	}
}

export const environment = new Environment();
