import axios, { AxiosResponse, AxiosInstance } from 'axios';

class AW_API {
	private _axios: AxiosInstance = axios.create();

	get axios(): AxiosInstance {
		return this._axios;
	}

	set axios(val: AxiosInstance) {
		this._axios = val;
	}
}

export const aw_api = new AW_API();
export { AxiosResponse };
