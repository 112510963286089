/*
    File:   event-bus.ts
    Desc:   This simple file creates an event bus to allow components to emit events
            and subscribe to events without needing to pass them up and down the dom tree.
            eg: import { EventBus } from '@/event-bus';
                EventBus.$emit('some-event', { data: 'some optional data'})
                EventBus.$on('some-event', this.someEventHandler);
    Created:    04/2019 SM
    Updates:    
*/

import Vue from 'vue';
export const EventBus = new Vue();
